import { Notifier } from "@airbrake/browser";

if (window.env.JEKYLL_ENV === "production") {
  try {
    window.airbrake = new Notifier({
      projectId: window.env.AIRBRAKE_PROJECT_ID,
      projectKey: window.env.AIRBRAKE_PROJECT_KEY,
      host: "https://panel.sutty.nl",
    });

    console.originalError = console.error;
    console.error = (...e) => {
      window.airbrake.notify(e.join(" "));
      return console.originalError(...e);
    };
  } catch (e) {
    console.error(e);
  }
}

import * as Turbo from "@hotwired/turbo";
Turbo.start();

import { Application } from "@hotwired/stimulus";
window.Stimulus = Application.start();
window.abortController = undefined;

import BodyScrollController from "./controllers/body_scroll_controller";
import DeviceDetectorController from "./controllers/device_detector_controller";
import ModalController from "./controllers/modal_controller";
import AuthorizeInteractionController from "./controllers/authorize_interaction_controller";
import TabsController from "./controllers/tabs_controller";
import WebsiteCloneController from "./controllers/website_clone_controller";
import GetATokenController from "./controllers/get_a_token_controller";
import ButtonCopyController from "./controllers/button_copy_controller";
import ContactController from "./controllers/contact_controller";

Stimulus.debug = window.env.JEKYLL_ENV !== "production";
Stimulus.register("body-scroll", BodyScrollController);
Stimulus.register("device-detector", DeviceDetectorController);
Stimulus.register("modal", ModalController);
Stimulus.register("authorize-interaction", AuthorizeInteractionController);
Stimulus.register("tabs", TabsController);
Stimulus.register("website-clone", WebsiteCloneController);
Stimulus.register("get-a-token", GetATokenController);
Stimulus.register("button-copy", ButtonCopyController);
Stimulus.register("contact", ContactController);

document.addEventListener("turbo:load", (event) => {
  document
    .querySelectorAll("a[href^='http://'],a[href^='https://'],a[href^='//']")
    .forEach((a) => {
      a.rel = "noopener";
      a.target = "_blank";
    });

  window.abortController = new AbortController();
});

document.addEventListener("turbo:visit", (event) => {
  window.abortController.abort();
});
