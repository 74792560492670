import { Controller } from "@hotwired/stimulus";
import doh from "dohjs/dist/doh";

// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error
class DnsError extends Error {
  constructor (dnsResponse, ...params) {
    super(...params);

    if (Error.captureStackTrace) Error.captureStackTrace(this, DnsError);

    this.name = "DnsError";
    this.dnsResponse = dnsResponse;
  }
}

export default class extends Controller {
  static targets = ["loading", "error", "success", "start", "waiting", "url", "ipns", "hyper"];
  static values = {
    distributedPress: {
      type: String,
      default: "https://api.distributed.press",
    },
    nextStep: String,
  };

  toggle (nextTarget) {
    for (const currentTarget of ["error", "success", "start", "waiting"]) {
      for (const target of this[`${currentTarget}Targets`]) {
        target.classList[(nextTarget === currentTarget) ? "remove" : "add"]("d-none");
      }
    }
  }

  async submit (event) {
    this.toggle("waiting");

    // XXX: Wait until token is issued
    if (event.target.dataset.wait === "true") {
      setTimeout(() => this.submit(event), 100);
      return;
    }

    const distributedPress = new URL(this.distributedPressValue);

    distributedPress.pathname = "/v1/sites";
    const domain = this.websiteUrl.hostname;

    const newSite = {
      domain,
      public: true,
      protocols: {
        http: true,
        hyper: true,
        ipfs: true,
      },
    };

    const method = "POST";
    const headers = {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${window.sessionStorage.token}`,
    };

    let body = JSON.stringify(newSite);

    try {
      let response = await fetch(distributedPress, { method, headers, body });
      const responseContent = await response.json();

      switch (response.status) {
        case 500:
          throw new Error("There was an error on Distributed Press and we couldn't clone your website!");
        case 402:
          // If the token already has another site attached, we fail
          if (!responseContent.message.match(new RegExp(` ${domain.replace(/\./g, '\\.')}$`))) {
            throw new Error(responseContent.message);
          }
        default:
          break;
      }

      // TODO: Don't hardcode protocols
      for (const protocol of ["ipns","hyper"]) {
        this[`${protocol}Target`].href = `${protocol}://${domain}`;
        this[`${protocol}Target`].innerText = `${protocol}://${domain}`;
      }

      // XXX: This will take some time
      distributedPress.pathname = `/v1/sites/${domain}/clone`;
      response = await fetch(distributedPress, { method: 'POST', headers, body: "{}" });

      if (response.ok) {
        distributedPress.pathname = `/v1/sites/${domain}`;

        const site = await (await fetch(distributedPress, { method: 'GET', headers })).json();

        this.toggle("success");
      } else {
        // XXX: Remove the website if we couldn't clone it to let people
        // try with another one.
        distributedPress.pathname = `/v1/sites/${domain}`;

        await fetch(distributedPress, { method: 'DELETE', headers, body: "{}" });

        throw new Error("Couldn't clone the website");
      }
    } catch (e) {
      console.error(domain, e);

      this.toggle("error");
    }
  }

  get websiteUrl () {
    if (!this._websiteUrl) {
      const searchParams = new URLSearchParams(window.location.search);

      this._websiteUrl = new URL(searchParams.get("website"));
    }

    return this._websiteUrl;
  }

  async loadingTargetConnected (loadingTarget) {
    try {
      const distributedPress = new URL(this.distributedPressValue);
      const distributedPressNameserver = distributedPress.hostname;
      window.resolver = new doh.DohResolver('https://1.1.1.1/dns-query');

      const response = await window.resolver.query(`_dnslink.${this.websiteUrl.hostname}.`, "NS");

      // The RR must exist
      if (response.rcode !== "NOERROR") throw new DnsError(response, "NOERROR");

      // The DP backend must be found
      if (!response.answers.some(x => x.type === "NS" && x.data === distributedPressNameserver)) throw new DnsError(response, "EMPTY");

      this.urlTarget.value = this.websiteUrl.toString();

      this.toggle("success");
    } catch (e) {
      console.error(e, e.dnsResponse);

      this.toggle("error");
    }
  }
}
