import { Controller } from "@hotwired/stimulus";
import DeviceDetector from "device-detector-js";

export default class extends Controller {
  static targets = [
    "clientName",
    "clientVersion",
    "osName",
    "osVersion",
    "viewportWidth",
    "viewportHeight",
    "devicePixelRatio",
    "deviceType",
    "help",
  ];

  connect() {
    this.element.style.zIndex = 10000;

    this.clientNameTarget.innerText = this.device.client.name;
    this.clientVersionTarget.innerText = this.device.client.version;
    this.osNameTarget.innerText = this.device.os.name;
    this.osVersionTarget.innerText = this.device.os.version;
    this.deviceTypeTarget.innerText = this.device.device.type;

    this.viewportWidthTarget.innerText = window.innerWidth;
    this.viewportHeightTarget.innerText = window.innerHeight;
    this.devicePixelRatioTarget.innerText = window.devicePixelRatio;
  }

  get device () {
    if (!this._device) {
      const deviceDetector = new DeviceDetector();
      this._device = deviceDetector.parse(navigator.userAgent);
    }

    return this._device;
  }

  resized (event) {
    this.viewportWidthTarget.innerText = window.innerWidth;
    this.viewportHeightTarget.innerText = window.innerHeight;
  }

  outline (event) {
    let style = document.head.querySelector('style#outline');

    if(style){
      style.remove();
    } else {
      style = document.createElement('style');
      style.id = 'outline';
      style.append("* { outline: 1px solid pink }");

      document.head.appendChild(style);
    }
  }

  toggleHelp (event) {
    this.helpTarget.hidden = !this.helpTarget.hidden;
  }
}
