import { Controller } from "@hotwired/stimulus";

/*
 * Sólo permite enviar el formulario de contacto después de unos
 * segundos, para evitar el spam.
 */
export default class extends Controller {
  static targets = ["counter", "submit"];
  static values = {
    delay: {
      type: Number,
      default: 30,
    },
    current: Number,
    interval: Number,
  };

  connect() {
    this.currentValue = this.delayValue;
    this.submitTarget.disabled = true;

    this.intervalValue = setInterval(() => {
      if (this.currentValue === 0) {
        clearInterval(this.intervalValue);

        this.submitTarget.disabled = false;
      } else {
        this.currentValue = this.currentValue - 1;
      }
    }, 1000);
  }

  currentValueChanged(currentValue) {
    this.counterTarget.innerText = currentValue;
  }
}
