import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["error", "token"];

  static values = {
    nextStep: String,
    genericError: String,
    distributedPress: {
      type: String,
      default: "http://127.0.0.1:8080",
    },
  };

  connect() {}

  errorTargetConnected(errorTarget) {
    this.genericErrorValue = errorTarget.innerHTML;
  }

  tokenTargetConnected(tokenTarget) {
    tokenTarget.innerHTML = window.sessionStorage.token;
  }

  input(event) {
    event.target.form.elements.name.setCustomValidity("");

    this.errorTarget.innerHTML = this.genericErrorValue;
  }

  async submit(event) {
    event.target.dataset.wait = true;

    const distributedPress = new URL(this.distributedPressValue);
    const formData = new FormData(event.target);
    const newPublisher = {};

    for (const key of formData.keys()) {
      newPublisher[key] = formData.get(key);
    }

    const { token, name } = window.sessionStorage;

    // XXX: Skip token creation if we have a token and email didn't
    // change
    if (token && name && name === newPublisher.name) {
      if (this.nextStepValue) {
        Turbo.visit(decodeURIComponent(this.nextStepValue));
      }

      event.target.dataset.wait = false;
      return;
    }

    distributedPress.pathname = "/v1/publisher/trial";
    const method = "POST";
    const headers = { "Content-Type": "application/json" };
    const body = JSON.stringify(newPublisher);

    try {
      const response = await fetch(distributedPress, { method, body, headers });

      if (response.ok) {
        window.sessionStorage.setItem("token", await response.text());
        window.sessionStorage.setItem("name", newPublisher.name);

        if (this.nextStepValue) Turbo.visit(decodeURIComponent(this.nextStepValue));
      } else {
        const error = await response.json();

        throw new Error(error.message);
      }
    } catch (e) {
      console.error(e);

      this.errorTarget.innerHTML = e.message;
      event.target.elements.name.setCustomValidity(e.message);
      event.target.classList.add("was-validated");
    }

    event.target.dataset.wait = false;
  }
}
