import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["text", "icon", "confirmationIcon", "confirmation"];
  static values = {
    text: String,
    confirmation: String,
  };

  async copy(event) {
    if (!("clipboard" in navigator)) return;

    try {
      let text = this.textValue.trim();

      if (this.hasTextTarget) {
        text =
          this.textTarget.innerText.trim() || this.textTarget.innerHTML.trim();
      }

      await navigator.clipboard.writeText(text);
    } catch (e) {
      console.error("Couldn't copy", e);
      return;
    }

    const confirmationTarget = this.hasConfirmationTarget
      ? "confirmationTarget"
      : "element";

    this[confirmationTarget].innerText = this.confirmationValue;

    if (this.hasIconTarget) this.iconTarget.classList.add("d-none");
    if (this.hasConfirmationIconTarget)
      this.confirmationIconTarget.classList.remove("d-none");
  }
}
